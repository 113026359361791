.App {
  text-align: center;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Merri-sans";
  font-weight: 400;
}

@media screen and (max-width: 480px) {
  .App {
    text-align: center;
  }
  .social-container {
    display: block;
  }
}
