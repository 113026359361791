@import "../../variables.scss";

.message-page-container {
  max-width: 100vw;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .message-container {
    width: 100%;
    height: calc(100vh - 90px);
    margin-top: 90px;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .message-content {
      padding-left: 60px;
      text-align: left;
      font-size: 24px;
      color: $dark-blue;
      line-height: 38px;
      span {
        color: $light-blue;
      }

      .home-btn {
        text-decoration: none;
        color: $white;
        border: 1px solid;
        padding: 16px 24px;
        border-radius: 8px;
        background-color: $dark-blue;
        font-size: $large;
        transition: all 0.3s ease;
        &:hover {
          background-color: $light-blue;
          // font-size: $x-large;
        }
      }
    }
  }
}
.message-footer {
  width: 100%;
  height: calc(100vh - 90px);
  background-color: $dark-blue;
}

@media screen and (max-width: 480px) {
  .message-page-container {
    padding-bottom: 60px;
    .message-container {
      height: calc(100vh - 155px);
      .message-content {
        font-size: $small;
        padding-left: 8px;
        line-height: 24px;

        .home-btn {
          text-decoration: none;
          color: $white;
          border: 1px solid;
          padding: 12px 16px;
          border-radius: 4px;
          background-color: $dark-blue;
          font-size: $small;
          transition: all 0.3s ease;
          &:hover {
            background-color: $light-blue;
            // font-size: $x-large;
          }
        }
      }
    }
    .message-footer {
      height: 100%;
    }
  }
}
