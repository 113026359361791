@import "../../variables.scss";

.line {
  width: 100%;
  height: 2px;
  background-color: $light-blue;
  //   margin-bottom: 24px;
}
a {
  color: $light-blue;
  font-size: $small;
}
.datenschutz-page-container {
  overflow-x: hidden;
  max-width: 100%;
  // height: 100%;
  margin-top: 90px;
  display: flex;
  flex-direction: column;
  align-items: center;

  //   padding-left: 64px;
  .datenschutz-wrapper {
    width: 95%;
    min-height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .title-container {
      width: 75%;
      height: 150px;
      display: flex;
      flex-direction: column;
      font-display: column;
      align-items: center;
      justify-content: space-evenly;
      h1 {
        color: $dark-blue;
        font-size: 50px;
        text-transform: uppercase;
      }
    }
    .section-1-container {
      width: 75%;
      .section-1 {
        width: 100%;
        margin-bottom: 30px;
        text-align: left;

        p {
          font-size: $small;
          width: 100%;
        }
        li {
          margin-bottom: 8px;
          font-size: $small;
        }
      }
    }
  }
  .footer-container {
    width: 100%;
    height: calc(100vh - 90px);
    background-color: $dark-blue;
  }
}

@media screen and (max-width: 480px) {
  .line {
    width: 100%;
    height: 2px;
    background-color: $light-blue;
    //   margin-bottom: 24px;
  }
  .datenschutz-page-container {
    overflow-x: hidden;
    margin-bottom: 60px;
    justify-content: center;
    .datenschutz-wrapper {
      width: 92.5%;
      justify-content: center;
      .title-container {
        width: 100%;
        height: 90px;
        h1 {
          font-size: 24px;
        }
      }
      .section-1-container {
        width: 100%;
      }
    }
    .footer-container {
      width: 100%;
      height: 100%;
      background-color: $dark-blue;
    }
  }
}
