@import "../../variables.scss";
.aboutus-page-container {
  max-width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
  scroll-snap-type: y mandatory;
  background-color: $gray-0;
  .about-slider-contianer {
    width: 100%;
    height: calc(100vh - 90px);
    margin-top: 90px;
  }
  .team-section-container {
    width: 100%;
    .title-container {
      width: 100%;
      height: 33.3vh;
      display: flex;
      align-items: center;
      justify-content: center;
      .title {
        width: 75%;
        font-size: 50px;
        font-family: "Merri";
        color: $dark-blue;
        letter-spacing: 1px;
        word-spacing: 4px;
      }
    }
    .Dr-N-container {
      width: 100%;
      min-height: calc(100vh - 90px);
      display: flex;
      .Dr-N-img-container {
        width: 45%;
        height: calc(100vh - 90px);
        // background-color: blue;
        display: flex;
        // background-color: red;
        align-items: center;
        justify-content: center;
        .Dr-N-img {
          width: 550px;
          height: 650px;
          background-image: url("../../../../public/imgs/nawras2.webp");
          background-size: cover;
          background-position: center;
          border-radius: 24px;
          transition: all 0.5s ease;

          // &:hover {
          //   background-position: left;
          // }
        }
      }
      .Dr-N-cv-container {
        width: 55%;
        min-height: calc(100vh - 90px);
        // height: 90vh;
        // background-color: blue;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;
        text-align: left;
        margin-bottom: 24px;
        .dr-name {
          color: $gray-6;
          height: 50px;
          font-family: "Merri";
          width: 100%;
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: flex-start;
          font-size: 24px;

          h3 {
            width: 100%;
            height: 50px;
            font-weight: 900;
            // background-color: red;
          }
        }
        .dr-job {
          color: $gray-5;
          height: 75px;
          font-size: 18px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;

          p {
            width: 100%;
            // height: 50px;
            font-weight: 700;
          }
        }
        .dr-cv {
          background-color: red;
          // min-height: 15%;
        }
      }
    }
    .Dr-P-container {
      background-color: $gray-0;
      min-height: calc(100vh - 90px);
      width: 100%;
      display: flex;
      align-items: center;
      margin-bottom: 90px;
      // justify-content: center;
      // background-color: red;
      .Dr-P-img-container {
        width: 51%;
        height: calc(100vh - 90px);
        // background-color: blue;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: red;
        border-radius: 24px;

        .Dr-P-img {
          width: 38vw;
          height: 90%;
          background-image: url("../../../../public/imgs/woman.jpg");
          background-size: cover;
          background-repeat: no-repeat;
          background-position: 50% 25%;
          border-radius: 24px;
          transition: all 0.5s ease;
        }
      }

      .Dr-P-cv-container {
        width: 45%;
        height: 100%;
        display: flex;
        flex-direction: column;
        text-align: right;
        margin-top: 24px;

        .dr-name {
          // background-color: red;
          color: $gray-6;

          height: 50px;
          font-family: "Merri";
          // background-color: green;
          width: 100%;
          display: flex;
          flex-direction: column;
          // align-items: center;
          justify-content: flex-start;
          font-size: 24px;

          h3 {
            width: 100%;
            height: 50px;
            font-weight: 900;
            // background-color: red;
          }
        }
        .dr-job {
          color: $gray-5;
          height: 75px;
          font-size: 18px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          p {
            width: 100%;
            // height: 50px;
            font-weight: 700;
          }
        }
      }
    }
    .Nurses-container {
      width: 100%;
      // background-color: black;
      display: flex;
      align-items: flex-end;
      justify-content: center;

      .Nurses-wrapper {
        width: 100%;
        min-height: calc(100vh - 90px);
        // background-color: blue;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-evenly;
      }
    }
  }
  .about-footer {
    // scroll-snap-align: end;
    width: 100%;
    height: calc(100vh - 90px);
    background-color: $dark-blue;
  }
}

@media screen and (max-width: 480px) {
  .aboutus-page-container {
    margin-bottom: 65px;
    .about-slider-contianer {
      margin-top: 90px;
      height: calc(50vh - 90px);
    }
    .team-section-container {
      .title-container {
        height: 100px;
        // background-color: red;
        .title {
          font-size: 24px;
        }
      }
      .Dr-N-container {
        flex-direction: column;
        margin-bottom: 0px;
        min-height: 600px;

        .Dr-N-img-container {
          width: 100%;
          height: 100%;

          .Dr-N-img {
            width: 90%;
            height: 450px;
          }
        }
        .Dr-N-cv-container {
          width: 100%;
          min-height: 25svh;
          align-items: center;
          justify-content: flex-start;
          // background-color: red;
          text-align: center;
          // margin-bottom: 60px;
          .dr-name {
            font-size: $large;
            height: 30px;
            h3 {
              height: 40px;
            }
          }
          .dr-job {
            font-size: $medium;
          }
        }
      }
      .Dr-P-container {
        flex-direction: column-reverse;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 0px;
        // background-color: green;
        margin-top: 0px;
        min-height: 625px;
        .Dr-P-img-container {
          width: 100%;
          height: 100%;
          // background:red;
          align-items: center;
          // justify-content: flex-start;
          .Dr-P-img {
            width: 90%;
            height: 450px;
          }
        }
        .Dr-P-cv-container {
          width: 100%;
          height: 10px;
          display: flex;
          flex-direction: column;
          text-align: center;
          margin-top: 0px;
          .dr-name {
            font-size: $large;
            height: 40px;
          }
          .dr-job {
            font-size: $medium;
          }
        }
      }
    }
    .about-footer {
      width: 100%;
      height: 100%;
      background-color: $dark-blue;
    }
  }
}
