@import "../variables.scss";
.treatment-process-card {
  width: 90%;
  height: 95%;
  background-color: $gray-1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-evenly;
  text-align: left;
  .card-img-container {
    width: 100px;
    height: 100px;
    // background-color: red;
    .img {
      width: 100%;
      height: 100%;
    }
  }
  .txt-container {
    .txt-header-container {
      h4 {
        font-size: $medium;
        font-weight: 900;
      }
    }
    .txt-content-container {
      p {
        font-size: $medium;
        line-height: 24px;
      }
    }
  }
}
.animate-1 {
  background-color: red;
}
@media screen and (max-width: 480px) {
  .treatment-process-card {
    align-items: flex-start;
    justify-content: space-evenly;
    text-align: left;
    .card-img-container {
      width: 75px;
      height: 75px;
      // background-color: red;
      .img {
        width: 100%;
        height: 100%;
      }
    }
    .txt-container {
      .txt-header-container {
        h4 {
          font-size: $medium;
          font-weight: 700;
        }
      }
      .txt-content-container {
        p {
          font-size: $small;
          line-height: 24px;
        }
      }
    }
  }
}
