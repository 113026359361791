@import "../../variables.scss";
.adults-page-container {
  max-width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
  scroll-snap-type: y mandatory;
  .adults-first-container {
    margin-top: 90px;
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .adults-treatment-container {
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .adults-eight-container {
    // scroll-snap-align: end;
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-blue;
  }
}

@media screen and (max-width: 480px) {
  .adults-page-container {
    margin-bottom: 70px;
    width: 100vw;
    height: 100%;
    .adults-first-container {
      height: 100%;
      margin-bottom: 34px;
    }
    .adults-treatment-container {
      height: 100%;
    }
    .adults-eight-container {
      height: 100%;
    }
  }
}
