@import "../../variables.scss";

.ContactContainer {
  height: 100%;
  max-width: 100vw;
  font-family: "Merri";
  font-weight: 700;
  background-color: $gray-0;
  .contact-form-container {
    margin-top: 90px;
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    align-items: flex-start;
    justify-content: center;
    .contact-form {
      width: 93%;
      height: 98%;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      font-size: $large;
      .row-container {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .first {
          width: 10%;
          font-size: $xx-large;
          color: $gray-7;
          text-align: left;
          // background-color: green;
          label {
            cursor: pointer;
          }
          .contact-header {
            font-size: 40px;
            color: $dark-blue;
            text-transform: capitalize;
            letter-spacing: 1px;
          }
        }
        .secound {
          width: 85%;
          // height: 100%;
          input[type="text"],
          input[type="email"],
          input[type="number"],
          select,
          textarea {
            padding: 14px 24px;
            border-radius: 4px;
            border: 1px solid $gray-2;
            font-family: "Merri-sans";
            font-weight: 400;
            transition: all 0.2s ease-out;
            &:focus {
              border: 2px solid $light-blue;
            }
          }

          #phone::-webkit-inner-spin-button,
          #phone::-webkit-outer-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }
          .input-name-opt {
            width: calc(90% + 50px);
            font-size: $large;
          }
          .input-name {
            width: 90%;
            font-size: $large;
          }
          textarea {
            height: 175px;
            min-width: 90%;
            max-width: 90%;
            max-height: 300px;
          }
          .input-name-last {
            width: calc(90% + 54px);
            height: 60px;
            border-radius: 8px;
            font-size: $large;
            background-color: $dark-blue;
            color: $gray-0;
            cursor: pointer;
            transition: all 0.2s ease-out;
            &:hover {
              background-color: $light-blue;
            }
          }
          .input-name-last {
            width: 95%;
            height: 60px;
            border-radius: 8px;
            font-size: $large;
            background-color: $dark-blue;
            color: $gray-0;
            cursor: pointer;
            border: 1px solid $dark-blue;
            &:hover {
              background-color: $light-blue;
              border: 1px solid $light-blue;
            }
          }
        }
      }
    }
  }
  .contact-footer {
    width: 100%;
    height: calc(100vh - 90px);
    background-color: $dark-blue;
  }
}

*:focus {
  outline: none;
}

@media screen and (max-width: 480px) {
  .ContactContainer {
    height: 100%;
    width: 100vw;
    margin-bottom: 60px;
    .contact-form-container {
      width: 100%;
      height: 100%;
      .contact-form {
        font-size: $small;
        width: 95%;

        .row-container {
          width: 100%;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // background-color: red;
          .first {
            width: 100%;
            height: 50px;
            // background-color: green;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            font-size: $small;
            .contact-header {
              font-size: 24px;
              color: $dark-blue;
              text-transform: capitalize;
              letter-spacing: 1px;
            }
          }
          .secound {
            width: 100%;
            input[type="text"],
            input[type="email"],
            input[type="number"],
            select,
            .input-name-opt,
            textarea {
              padding: 14px 8px;
              border-radius: 4px;
              border: 1px solid $gray-2;
              font-family: "Merri-sans";
              font-weight: 400;
              transition: all 0.2s ease-out;
              &:focus {
                border: 2px solid $light-blue;
              }
            }
            .input-name {
              width: 95%;

              font-size: $small;
            }
            .input-name-opt {
              width: calc(90% + 38px);
              font-size: $small;
            }

            textarea {
              height: 200px;
              min-width: 95%;
              max-width: 95%;
              max-height: 300px;
              // margin-bottom: 160px;
            }
            .input-name-last {
              margin-bottom: 44px;
              width: calc(90% + 38px);
              height: 48px;
              border-radius: 8px;
              font-size: $large;
              background-color: $dark-blue;
              color: $gray-0;
              cursor: pointer;
              transition: all 0.2s ease-out;
              &:hover {
                background-color: $light-blue;
              }
            }
          }
        }
      }
    }
    .contact-footer {
      width: 100%;
      height: 100%;
      background-color: $dark-blue;
    }
  }
}
