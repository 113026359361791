@import "../variables.scss";
.social-container {
  display: none;
}

@media screen and (max-width: 480px) {
  .social-container {
    position: fixed;
    bottom: 0%;
    height: 65px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $gray-2;
    // border-radius: 4px;
    z-index: 2000;
    .social-wrapper {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: 24px;
      padding-right: 24px;
      .icons-container {
        .icon {
          font-size: $xxxx-large;
          color: $dark-blue;
        }
      }
    }
  }
}
