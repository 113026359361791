html {
  font-size: 62.5%;
  /* overflow: hidden; */
  /* height: 100%; */
  /* position: relative; */
}

body {
  /* overflow: hidden; */
  /* height: 100%; */
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* -webkit-overflow-scrolling: touch; */
  font-size: 1.6rem;
  /* position: absolute; */
}
/* #site-wrapper {
  overflow: overlay;
  height: 100%;
} */
.cky-revisit-bottom-left {
  bottom: 8px !important;
  left: 8px !important;
}
@font-face {
  font-family: "Merri";
  src: url("./fonts/Merriweather/Merriweather-Black.ttf"),
    url("./fonts/Merriweather/Merriweather-BlackItalic.ttf"),
    url("./fonts/Merriweather/Merriweather-BoldItalic.ttf"),
    url("./fonts/Merriweather/Merriweather-Italic.ttf"),
    url("./fonts/Merriweather/Merriweather-Light.ttf"),
    url("./fonts/Merriweather/Merriweather-LightItalic.ttf"),
    url("./fonts/Merriweather/Merriweather-Regular.ttf");
  font-weight: 900;
  font-style: normal;
}
@font-face {
  font-family: "Merri";
  src: url("./fonts/Merriweather/Merriweather-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}
@font-face {
  font-family: "Merri";
  src: url("./fonts/Merriweather/Merriweather-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Merri";
  src: url("./fonts/Merriweather/Merriweather-Light.ttf");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Merri";
  src: url("./fonts/Merriweather/Merriweather-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Merri-sans";
  src: url("./fonts/Merriweather_Sans/static/MerriweatherSans-Regular.ttf");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Merri-sans";
  src: url("./fonts/Merriweather_Sans/static/MerriweatherSans-Medium.ttf");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Merri-sans";
  src: url("./fonts/Merriweather_Sans/static/MerriweatherSans-Bold.ttf");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Merri-sans";
  src: url("./fonts/Merriweather_Sans/static/MerriweatherSans-ExtraBold.ttf");
  font-weight: 900;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

::-webkit-scrollbar {
  width: 14px;
  height: 5px;
}
::-webkit-scrollbar-thumb {
  background: #8c959f;
  border-radius: 8px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* margin-top: 90px; */
  display: none;
}

@media screen and (max-width: 480px) {
  html {
    /* overflow: hidden; */
    width: 100%;
    /* position: relative; */
  }
  body {
    font-size: 1.4rem;
    /* height: 100%; */
    /* overflow-y: overlay; */
    /* -webkit-overflow-scrolling: touch; */
    /* position: fixed; */
  }

  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 8px;
    background-color: #a8a8a8;
    margin-top: 90px;
  }
  ::-webkit-scrollbar-track {
    display: none;
  }
}
