// -------------------------------------- Colors --------------------------------------

$dark-blue: #1f5ca2;
$light-blue: #20ade4;
$action: #ffff00;
$white: #ffffff;
$black: #1b1f24;
$gray-0: #f6f8fa;
$gray-1: #eaeef2;
$gray-2: #d0d7de;
$gray-3: #afb8c1;
$gray-4: #8c959f;
$gray-5: #6e7781;
$gray-6: #57606a;
$gray-7: #424a53;
$gray-8: #32383f;
$gray-9: #24292f;
$blue-0: #ddf4ff;
$blue-1: #b6e3ff;
$blue-2: #80ccff;
$blue-3: #54aeff;
$blue-4: #218bff;
$blue-5: #0969da;
$blue-6: #0550ae;
$blue-7: #033d8b;
$blue-8: #0a3069;
$blue-9: #002155;
$green-0: #dafbe1;
$green-1: #aceebb;
$green-2: #6fdd8b;
$green-3: #4ac26b;
$green-4: #2da44e;
$green-5: #1a7f37;
$green-6: #116329;
$green-7: #044f1e;
$green-8: #003d16;
$green-9: #002d11;
$yellow-0: #fff8c5;
$yellow-1: #fae17d;
$yellow-2: #eac54f;
$yellow-3: #d4a72c;
$yellow-4: #bf8700;
$yellow-5: #9a6700;
$yellow-6: #7d4e00;
$yellow-7: #633c01;
$yellow-8: #4d2d00;
$yellow-9: #3b2300;
$orange-0: #fff1e5;
$orange-1: #ffd8b5;
$orange-2: #ffb77c;
$orange-3: #fb8f44;
$orange-4: #e16f24;
$orange-5: #bc4c00;
$orange-6: #953800;
$orange-7: #762c00;
$orange-8: #5c2200;
$orange-9: #471700;
$red-0: #ffebe9;
$red-1: #ffcecb;
$red-2: #ffaba8;
$red-3: #ff8182;
$red-4: #fa4549;
$red-5: #cf222e;
$red-6: #a40e26;
$red-7: #82071e;
$red-8: #660018;
$red-9: #4c0014;
$purple-0: #fbefff;
$purple-1: #ecd8ff;
$purple-2: #d8b9ff;
$purple-3: #c297ff;
$purple-4: #a475f9;
$purple-5: #8250df;
$purple-6: #6639ba;
$purple-7: #512a97;
$purple-8: #3e1f79;
$purple-9: #2e1461;
$pink-0: #ffeff7;
$pink-1: #ffd3eb;
$pink-2: #ffadda;
$pink-3: #ff80c8;
$pink-4: #e85aad;
$pink-5: #bf3989;
$pink-6: #99286e;
$pink-7: #772057;
$pink-8: #611347;
$pink-9: #4d0336;
$coral-0: #fff0eb;
$coral-1: #ffd6cc;
$coral-2: #ffb4a1;
$coral-3: #fd8c73;
$coral-4: #ec6547;
$coral-5: #c4432b;
$coral-6: #9e2f1c;
$coral-7: #801f0f;
$coral-8: #691105;
$coral-9: #510901;

// ---------------------------------------------- Typography -------------------------------------

$xx-small: 1rem;
$x-small: 1.2rem;
$small: 1.4rem;

$medium: 1.6rem;

$large: 1.8rem;

$x-large: 2rem;
$xx-large: 2.2rem;
$xxx-large: 2.4rem;
$xxxx-large: 2.8rem;

$huge: 4.8rem;

$huge-mobile: 3.8rem;
