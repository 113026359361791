@import "../../variables.scss";
.leistungen-main-container {
  max-width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
  scroll-snap-type: y mandatory;

  .first-container {
    margin-top: 90px;
    width: 100%;
    height: calc(100vh - 90px);
    // background-color: red;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .secound-container {
    width: 100%;
    height: calc(100vh - 90px);
    color: white;

    display: flex;

    .secound-left-container {
      width: 50%;
      height: 100%;
      .adult-img {
        width: 100%;
        height: 100%;
        background-image: url("../../../../public/imgs/hero1.webp");
        background-size: 160%;

        background-position: top;
        transition: all 0.5s ease;
        &:hover {
          background-size: 165%;
          transition: all 0.7s ease;
        }
      }
    }
    .secound-right-container {
      width: 50%;
      height: 100%;
      background-color: #1f5ca2;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .adults-title-container {
        width: 100%;
        height: 30%;
        .adults-title {
          font-size: 38px;
          font-weight: 900;
          font-family: "Merri";
          letter-spacing: 2px;
        }
      }
      .adults-btn-container {
        width: 75%;
        height: 15%;
        .adults-btn {
          text-decoration: none;
          color: $dark-blue;
          font-size: $large;
          border: 1px solid white;
          border-radius: 8px;
          padding: 16px 26px;
          background-color: #fff;
          &:hover {
            background-color: $light-blue;
            color: $white;
            border-color: $light-blue;
          }
        }
      }
    }
  }
  .third-container {
    overflow: hidden;
    width: 100%;
    height: calc(100vh - 90px);
    color: white;
    display: flex;
    .third-left-container {
      width: 50%;
      height: 100%;
      background-color: $light-blue;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .kids-title-container {
        width: 100%;
        height: 30%;
        .kids-title {
          font-size: 42px;
          // height: 25%;
          font-weight: 900;
          font-family: "Merri";
          letter-spacing: 2px;
        }
      }
      .kids-btn-container {
        width: 75%;
        height: 15%;
        .kids-btn {
          text-decoration: none;
          color: $dark-blue;
          font-size: $large;
          border: 1px solid white;
          border-radius: 8px;
          padding: 16px 26px;
          background-color: #fff;
          &:hover {
            background-color: $dark-blue;
            color: $white;
            border-color: $dark-blue;
          }
        }
      }
    }
    .third-right-container {
      width: 50%;
      height: 100%;

      background-image: url("../../../../public/imgs/hero2.webp");
      background-size: 160%;
      background-position: center;
      transition: all 0.7s ease;
      &:hover {
        background-position: center;
        background-size: 165%;
      }
    }
  }
  .leistungen-footer-container {
    width: 100%;
    height: calc(100vh - 90px);
    background-color: $dark-blue;
  }
}

// ################################ MOBILE DESIGN ##############################################

@media screen and (max-width: 480px) {
  .leistungen-main-container {
    margin-bottom: 70px;
    width: 100vw;
    height: 100%;
    .first-container {
      height: 100%;
      margin-bottom: 24px;
    }
    .secound-container {
      width: 100%;
      height: 100%;
      color: white;
      display: flex;
      flex-direction: column;

      .secound-left-container {
        width: 100%;
        height: 250px;
        .adult-img {
          background-size: cover;
          background-position: center;
          transition: all 0.5s ease;
          &:hover {
            background-position: center;
            background-size: cover;
          }
        }
      }
      .secound-right-container {
        width: 100%;
        height: 350px;
        .adults-title-container {
          height: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          // background-color: red;
          .adults-title {
            font-size: $xx-large;
            font-weight: 900;
            font-family: "Merri";
            letter-spacing: 2px;
          }
        }
        .adults-btn-container {
          // background-color: green;
          width: 100%;
          height: 33%;
          // background-color: red;
          display: flex;
          align-items: center;
          justify-content: center;
          .adults-btn {
            font-size: $small;
            padding: 14px 8px;
            &:hover {
              background-color: $light-blue;
              color: $white;
              border-color: $light-blue;
            }
          }
        }
      }
    }
    .third-container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;

      .third-left-container {
        width: 100%;
        height: 350px;
        .kids-title-container {
          height: 25%;
          display: flex;
          align-items: center;
          justify-content: center;
          .kids-title {
            font-size: $xx-large;
            font-weight: 900;
            font-family: "Merri";
            letter-spacing: 2px;
          }
        }
        .kids-btn-container {
          width: 95%;
          height: 33%;
          display: flex;
          align-items: center;
          justify-content: center;
          .kids-btn {
            font-size: $small;
            padding: 14px 14px;
          }
        }
      }
      .third-right-container {
        width: 100%;
        height: 300px;

        background-size: cover;
        background-position: center;
        transition: all 0.5s ease;
        &:hover {
          background-size: cover;
          background-position: center;
        }
      }
    }
    .leistungen-footer-container {
      width: 100%;
      height: 100%;
    }
  }
}
