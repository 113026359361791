@import "../../variables.scss";
.kids-page-container {
  max-width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
  scroll-snap-type: y mandatory;
  .kids-first-container {
    margin-top: 90px;
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .kids-treatment-container {
    width: 100%;
    height: calc(100vh - 90px);
    background-color: green;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .kids-eight-container {
    width: 100%;
    height: calc(100vh - 90px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: $dark-blue;
  }
}

@media screen and (max-width: 480px) {
  .kids-page-container {
    margin-bottom: 70px;
    width: 100vw;
    height: 100%;
    .kids-first-container {
      height: 100%;
      margin-bottom: 34px;
    }
    .kids-treatment-container {
      height: 100%;
    }
    .kids-eight-container {
      height: 100%;
      background-color: $dark-blue;
    }
  }
}
