@import "../variables.scss";
.home-page {
  max-width: 100vw;
  height: 100%;
  scroll-behavior: smooth;
  overflow: overlay;
  scroll-snap-type: y mandatory;
  overflow-x: hidden;

  .hero-section {
    max-width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .home-slider-container {
      width: 100%;
      height: 100vh;
    }
    .welcome-title-mobile-container {
      display: none;
    }
  }

  .hero-section2 {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-top: 90px;
    // background-color: $gray-2;

    .welcome-title-container {
      width: 1250px;
      height: 150px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .welcome-title {
        width: 95%;
        font-size: 34px;
        letter-spacing: 1px;
        color: $dark-blue;
        font-weight: 900;
        line-height: 58px;
        font-family: "Merri";
      }
    }

    .welcome-waves {
      width: 100%;
      height: 30vh;
      background-image: url("../../../public/imgs/waves.webp");
      background-size: 100% 100%;
      background-position: center;
      background-repeat: no-repeat;

      // border: 1px solid black;
      // background-color: red;
    }
    .welcome-content-container {
      width: 80%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-evenly;
      .welcome-last-1 {
        width: 100%;
        // background-color: red;
        .welcome-content {
          font-size: $large;
          font-weight: 400;
          letter-spacing: 1px;
          line-height: 34px;
          font-family: "Merri-sans";
          color: $gray-7;
          // background-color: green;
        }
      }
      .welcome-last-2 {
        width: 100%;
        height: 60px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        // background-color: yellow;
        .welcome-owner-txt {
          height: 75%;
          font-size: $large;
          font-weight: 700;
          letter-spacing: 0.75px;
          line-height: 32px;
          font-family: "Merri";
          cursor: pointer;
          color: $dark-blue;
          text-decoration: none;
          // background-color: black;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .welcome-owner-txt:hover {
          color: $light-blue;
        }
      }
      .welcome-btn {
        width: 100%;
        height: 90px;
        display: flex;
        align-items: center;
        justify-content: center;
        .termin-button {
          // width: 33.3%;
          .appointment-button {
            border-radius: 8px;
          }
        }
      }
    }
  }
  .treatment-process-section {
    width: 100%;
    height: calc(100%);
    background-color: $white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 70px;
    margin-bottom: 70px;
    .treatment-process-title-container {
      width: 94%;
      height: 120px;
      // background-color: green;
      display: flex;
      align-items: center;
      justify-content: center;
      h1 {
        font-size: 50px;
        color: $dark-blue;
        font-family: "Merri";
        font-weight: 900;
      }
    }
    .treatment-process-container {
      width: 94%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      // margin-bottom: 40px;
      // background-color: red;
      .treatment-proccess-card-conainer {
        width: 31%;
        height: 350px;
        background-color: $gray-1;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 10px;
        margin: 40px 0 20px 0;
        left: -33%;
        position: relative;
      }
      .card-1 {
        left: -33%;
      }
      .animate-1 {
        left: 0;
        transition: all 0.5s ease-out;
        z-index: 10;
      }
      .card-2 {
        left: -66%;
        opacity: 0;
      }
      .animate-2 {
        left: 0;
        opacity: 1;
        z-index: 9;
        transition: all 0.75s ease-out;
      }
      .card-3 {
        left: -99%;
        z-index: 8;
        opacity: 0;
      }
      .animate-3 {
        left: 0;
        opacity: 1;
        transition: all 1s ease-out;
        z-index: 8;
      }
      .card-4 {
        left: -33%;
        z-index: 7;
        opacity: 0;
      }
      .animate-4 {
        left: 0;
        opacity: 1;
        transition: all 1s ease-out;
        z-index: 7;
      }
      .card-5 {
        width: 65.4%;
        left: -100%;
        z-index: 6;
        opacity: 0;
      }
      .animate-5 {
        width: 65.4%;
        left: 0;
        opacity: 1;
        transition: all 1.25s ease-out;
        z-index: 6;
      }
    }
  }
  .hero-section3 {
    height: calc(100vh - 90px);
    background-color: #fff;
    scroll-snap-align: end;
    // margin-top: 90px;
    background-color: $dark-blue;
    display: flex;

    .last-section-container {
      width: 100%;
      height: 100%;
      background-color: $dark-blue;
      display: flex;
    }
    .la {
      color: red !important;
    }
  }
}

// ------------------------------------------------- IPAD DESIGN ------------------------------

// ------------------------------------------------- MOBILE DESIGN ------------------------------

@media screen and (max-width: 980px) {
  .home-page {
    max-block-size: 100%;
    height: 100%;
    margin-bottom: 60px;
    .hero-section {
      width: 100vw;
      height: 100%;
      margin-top: 90px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      align-items: flex-start;
      // background-color: red;
      .home-slider-container {
        width: 100%;
        height: calc(50vh - 90px);
      }
      .welcome-title-mobile-container {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 24px;
        // background-color: red;
        // scale: 0;

        .welcome-title-mobile {
          color: $gray-5;
          font-family: "Merri";
          font-size: $xxx-large;
          line-height: 38px;
          letter-spacing: 1px;
          color: $dark-blue;
          // background-color: black;
          margin-top: 0px;
          transform: scale(0);

          span {
            font-size: $medium;
            font-weight: 900;

            transition: scale 1s ease;
          }
        }
        .animate-welcome {
          // color: red;
          transform: scale(1);
          transition: all 1.8s ease-out;
        }
      }
    }
    .hero-section2 {
      height: 100%;
      margin-top: 0px;
      .welcome-title-container {
        display: none;
      }
      .welcome-waves {
        width: 100%;
        height: 150px;
        background-size: 100% 100%;
        background-position: center;
        margin-bottom: 30px;
      }
      .welcome-content-container {
        width: 95%;
        height: 100%;
        // background-color: red;
        .welcome-last-1 {
          width: 100%;
          height: 100%;
          .welcome-content {
            font-size: $small;
            letter-spacing: 0.5px;
            line-height: 24px;
            text-align: center;
          }
        }
        .welcome-last-2 {
          width: 100%;
          height: 100%;
          // background-color: red;

          .welcome-owner-txt {
            width: 100%;
            margin-top: 8px;
            height: 100%;
            font-size: $x-small;
            font-weight: 600;
            letter-spacing: 0.75px;
            line-height: 32px;
            font-family: "Merri";
            cursor: pointer;
            color: $gray-7;
            text-align: center;
            margin-bottom: 20px;
          }
        }
        .welcome-btn {
          height: 75px;
          .termin-button {
            width: 100%;
            // height: 60px;
          }
          .appointment-button {
            border-radius: 8px;
            font-size: $medium;
          }
        }
      }
      margin-bottom: 30px;
    }
    .treatment-process-section {
      margin-top: 0px;
      margin-bottom: 40px;
      .treatment-process-title-container {
        height: 70px;
        width: 95%;
        h1 {
          font-size: $xx-large;
        }
      }
      .treatment-process-container {
        flex-direction: column;
        .treatment-proccess-card-conainer {
          width: 100% !important;
          height: 360px !important;
          position: static;
        }
        .card-1 {
          transform: translateX(-100%);
        }
        .animate-1 {
          transform: translateX(0%);
          transition: transform 0.7s ease-out;
        }
        .card-2 {
          transform: translateX(100%);
        }
        .animate-2 {
          transform: translateX(0%);
          transition: transform 0.7s ease-out;
        }
        .card-3 {
          transform: translateX(-100%);
        }
        .animate-3 {
          transform: translateX(0%);
          transition: transform 0.7s ease-out;
        }
        .card-4 {
          transform: translateX(100%);
        }
        .animate-4 {
          transform: translateX(0%);
          transition: transform 0.7s ease-out;
        }
        .card-5 {
          transform: translateX(-100%);
        }
        .animate-5 {
          transform: translateX(0%);
          transition: transform 0.7s ease-out;
        }
      }
    }
    .hero-section3 {
      height: 100%;
      // display: flex;
      margin-top: 0px;
    }
  }
}
