@import "../variables.scss";
.footer-container {
  width: 100%;
  height: 100%;
  color: $gray-2;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  .footer-content-container {
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    justify-content: space-between;
    margin-top: 0;
    // border-bottom: 2px solid $black;
    .google-map-container {
      flex: 0.8;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      .map-before-loading {
        position: relative;
        width: 90%;
        height: 70%;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        background-image: url("../../../public/imgs/google-map.png");
        background-size: 100% 100%;
        background-position: center;
        border: 8px solid $light-blue;
        border-radius: 16px;
        .gmap-div-container {
          background-color: $white;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 316px;
          height: 116px;
          display: flex;
          align-items: center;
          justify-content: space-evenly;
          border-top-left-radius: 8px;
          border-bottom-right-radius: 8px;
          opacity: 0.75;
          .gmap-content {
            padding-left: 8px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: space-evenly;
            width: 65%;
            height: 100%;
            font-size: $x-small;
            color: $gray-7;
            text-align: left;
            .gmap-name {
              display: flex;
              align-items: center;
              width: 100%;
              font-size: 13px;
              font-weight: 900;
            }
            a {
              color: $light-blue;
              &:hover {
                color: $gray-3;
              }
            }
            .bewertung {
              display: flex;
              span {
                font-size: $x-small;
                padding-right: 4px;
              }
              .full-star {
                color: gold;
              }
              .half-star {
                color: gold;
                padding-right: 4px;
              }
            }
          }
          .gmap-direction {
            font-size: small;
            .route-icon-container {
              .route-icon {
                font-size: 48px;
                color: $light-blue;
              }
            }
            a {
              color: $light-blue;
            }
          }
        }

        .map-before-btn {
          margin-bottom: 16px;
          width: 180px;
          height: 50px;
          font-size: $small;
          font-weight: 700;
          border-radius: 8px;
          border: 3px solid $light-blue;
          cursor: pointer;
          background-color: $white;
          color: $light-blue;
          opacity: 0.9;
          transition: all 0.5s ease;

          &:hover {
            opacity: 1;
            background-color: $light-blue;
            border: 4px solid $light-blue;
            color: $white;
            font-weight: 700;
          }
        }
      }
      .google-map {
        position: relative;
        width: 90%;
        height: 70%;
        border-radius: 16px;
        box-shadow: rgba(0, 0, 0, 0.54) 0px 3px 8px;
        border: 8px solid $light-blue;
      }
    }

    .footer-content-top-container {
      flex: 1.14;
      height: 100%;
      // background-color: red;
      .footer-content-top {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 0;
        // background-color: black;
        .footer-content-left {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          // background-color: blue;
          .footer-left-details {
            // border: 1px solid white;
            width: 100%;
            height: 52%;
            text-align: left;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: space-evenly;
            font-family: "Merri-sans";
            font-weight: 300;
            font-size: $medium;
            // background-color: green;
            .details-name {
              width: 90%;
              height: 15%;
              display: flex;
              // background-color: yellow;
              align-items: center;
              justify-content: center;
              .name-icon {
                flex: 0.08;
                text-align: center;
                display: flex;
                align-items: center;
                justify-content: center;
                .footer-icon {
                  font-size: $xxx-large;
                }
              }
              .rose {
                width: 36px;
                height: 36px;
                color: white;
              }

              .name-txt {
                flex: 0.48;
                // color: red;
                font-size: $medium;
              }
              .name {
                font-size: $large;
                font-weight: 700;
              }
              .open-times {
                flex: 0.5;
                display: flex;
                font-size: $medium;
                // background-color: red;
                .day {
                  // background-color: yellow;
                  width: 70px;
                }
                .time {
                  padding-left: 16px;
                  // background-color: green;
                  span {
                    margin-left: 12px;
                    margin-right: 12px;
                  }
                }
              }
              .ot {
                font-size: $large;
                font-weight: 700;
                // color: red;
              }
            }
          }
          .last-open-times {
            width: 90%;
            height: 15%;
            // background-color: red;
            display: flex;
            align-items: flex-end;
            justify-content: center;
            .btn-footer-descktop {
              width: 96%;

              .termin-button {
                width: 100%;
                .appointment-button {
                  border-radius: 8px;
                }
              }
            }
          }
          .opening-times-mobile {
            display: none;
          }
        }
      }

      .btn-footer-mobile {
        width: 46%;
        height: 27%;
        display: flex;
        align-items: center;
        justify-content: center;
        // text-align: left;
        // border: 1px solid black;
        display: none;
      }
    }
  }

  .footer-content-bottom {
    background-color: $gray-1;
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: "Merri-sans";
    // border: 1px solid #fff;
    .footer-copy-right {
      // background-color: $red-5;
      font-family: "Merri-sans";
      font-weight: 400;
      width: 50%;
      height: 100%;
      // background-color: red;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-left: 52px;
      font-size: $small;
      color: $gray-7;
      letter-spacing: 0.5px;
      .copy-sign {
        margin-top: 5px;
        text-align: center;
        color: $gray-7;
        width: 4%;
        // background-color: red;
        .c-icon {
          font-size: $x-large;
        }
      }
      span {
        cursor: pointer;
        font-family: "Merri-sans";
        font-weight: 700;
        &:hover {
          color: $light-blue;
        }
      }
    }
    .footer-social-media {
      .footer-impressum-mobile {
        display: none;
      }
    }

    .footer-impressum {
      width: 50%;
      height: 100%;
      font-size: $small;
      display: flex;
      align-items: center;
      justify-content: space-evenly;
      a {
        color: $gray-9;
        transition: all 0.1s ease;
      }
      a:hover {
        color: $light-blue;
      }
      .footer-social-media-descktop {
        flex: 0.5;
        height: 50%;
        // border: 1px solid black;
        display: flex;
        align-items: center;
        justify-content: space-evenly;
        .social-icon-container {
          height: 100%;
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          text-decoration: none;
          color: $gray-7;

          .social-icon {
            width: 28px;
            height: 28px;
          }
        }
        .fb {
          color: $dark-blue;
        }
        .fb:hover {
          color: #3c99dc;
        }
        .ig {
          color: #c13584;
        }
        .ig:hover {
          color: #c13584;
        }
      }
    }
  }
}

// ######################### MOBILE ########################

@media screen and (max-width: 980px) {
  .footer-container {
    justify-content: space-between;
    width: 100%;
    height: 100%;
    .footer-content-container {
      height: 800px;
      flex-direction: column;
      justify-content: space-evenly;
      // background-color: red;

      .google-map-container {
        align-items: flex-start;
        justify-content: center;
        flex: 0.42;
        .map-before-loading {
          background-image: url("../../../public/imgs/google-map.png");
          background-size: cover;
          background-position: 100% 100%;
          width: 100%;
          height: 96%;
          border: none;
          border-radius: 0px;
          .gmap-div-container {
            top: 5px;
            left: 8px;
            width: 275px;
            justify-content: space-evenly;
            border-radius: 0px;
            .gmap-content {
              // background-color: red;
              width: 75%;
              .gmap-name {
                font-size: 12px;
              }
            }
            .gmap-direction {
              font-size: 12px;
              padding-right: 5px;
              .route-icon-container {
                .route-icon {
                  font-size: 40px;
                  color: $light-blue;
                }
              }
            }
          }
          .map-before-btn {
            margin-bottom: 10px;
            width: 180px;

            height: 44px;
            font-size: 1.4rem;
            font-weight: 700;
            border-radius: 5px;
            border: 3px solid $light-blue;
            cursor: pointer;
            background-color: $white;
            color: $light-blue;
            // opacity: 0.85;
          }
        }
        .google-map {
          width: 100%;
          height: 95%;
          border: none;
          border-radius: 0px;
        }
      }
      .footer-content-top-container {
        flex: 0.58;
        display: flex;
        flex-direction: column;
        // background-color: black;
        .footer-content-top {
          height: 90%;
          // background-color: green;
          .footer-content-left {
            // background-color: red;
            width: 100%;
            display: flex;
            align-items: flex-start;
            justify-content: space-between;
            // background-color: #fff;
            .footer-left-details {
              font-size: $small;
              height: 50%;
              // background-color: red;
              // margin-bottom: 8px;
              .details-name {
                justify-content: flex-start;
                align-items: flex-start;
                height: 20%;
                .name-icon {
                  padding-right: 2px;
                  flex: 0;
                  .footer-icon {
                    font-size: $xx-large;
                    margin-left: 16px;
                    margin-right: 12px;
                  }
                }
                .rose {
                  width: 22px;
                  height: 22px;
                  font-weight: bolder;
                  margin-left: 16px;
                  margin-right: 12px;
                }

                .name-txt {
                  flex: 0;
                  font-size: $x-small;
                  font-weight: 400;
                }
                .name {
                  font-size: $medium;
                  font-weight: 700;
                }
                .open-times {
                  display: none;

                  .time {
                    padding-left: 0px;
                  }

                  .btn-footer-descktop {
                    display: none;
                  }
                }
              }
              .email-name {
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: flex-start;

                .name-txt {
                  flex: 0.9;
                }
              }
            }
            .last-open-times {
              display: none;
            }
            .opening-times-mobile {
              width: 100%;
              height: 50%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: space-evenly;
              // margin-bottom: 8px;
              .open-times {
                display: flex;
                margin-left: 18px;
                font-size: $small;
                .day {
                  width: 65px;
                  text-align: left;
                }
                .time {
                  margin-left: 8px;
                  font-size: $x-small;
                  span {
                    margin-left: 12px;
                    margin-right: 12px;
                  }
                }
              }
              .open-times-header {
                font-weight: 700;
                font-size: $medium;
                letter-spacing: 0.5px;
              }
            }
          }
        }
        .btn-footer-mobile {
          width: 100%;
          height: 70px;
          display: block;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 8px;
          margin-bottom: 18px;

          .termin-button {
            width: 91%;
            height: 48px;
            .appointment-button {
              border-radius: 8px;
              font-size: $medium;
            }
          }
        }
      }
    }
    .footer-content-bottom {
      height: 70px;
      max-width: 100%;
      flex-direction: column-reverse;

      align-items: flex-end;
      justify-content: center;
      background-color: $white;
      .footer-copy-right {
        width: 100%;
        height: 50%;
        color: $gray-7;
        justify-content: center;
        width: 100vw;
        font-size: 11px;

        .copy-sign {
          margin-bottom: 4px;
          padding-right: 8px;
          font-size: 4px;
        }

        span {
          color: $gray-7;
          font-size: 11px;
          // display: none;
        }
      }
      .footer-social-media {
        width: 100%;

        display: flex;
        align-items: center;
        justify-content: center;
        .social-icon-container {
          width: 25%;
          font-size: 10px;
          color: $gray-2;
          align-items: center;
          justify-content: center;
        }
        .footer-impressum-mobile {
          display: block;
          width: 100%;
          height: 100%;
          font-size: $small;
          display: flex;
          align-items: center;
          justify-content: space-around;

          a {
            color: $gray-7;
            padding-left: 8px;
            padding-right: 8px;
          }
          .fb {
            .MuiSvgIcon-root {
              width: 24px;
              height: 24px;
              color: #3c99dc;
            }
          }
          .ig {
            .MuiSvgIcon-root {
              width: 24px;
              height: 24px;
              color: #c13584;
            }
          }
        }
      }
      .footer-impressum {
        display: none;
      }
    }
  }
}
