@import "../variables.scss";
.termin-button {
  width: 275px;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  display: flex;
  align-items: center;
  justify-content: center;
  .appointment-button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.8px;
    font-family: "Merri-sans";
    word-spacing: 5px;
    border-radius: 8px;
    cursor: pointer;
    background-color: $dark-blue;
    color: #fff;
    text-decoration: none;

    font-size: $x-large;
    font-weight: 700;
    transition: 0.2s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
      rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
      rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
  }
  :hover {
    // border: 1px solid $light-blue;
    background-color: $light-blue !important;
    color: #fff !important;
    box-shadow: rgba(17, 17, 26, 0.1) 0px 8px 24px,
      rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px;
  }
}

@media screen and (max-width: 480px) {
  .termin-button {
    width: 250px;
    height: 52px;
    .appointment-button {
      word-spacing: 3px;
      font-size: $medium;
      box-shadow: none;
    }
  }
}
