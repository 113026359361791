@import "../variables.scss";

.card-container {
  width: 25.1vw;
  height: 600px;
  // border-radius: 24px;
  padding-bottom: 15vh;
  // background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  transition: all 0.8s ease-out;
  // position: relative;
  // border: 2px solid $gray-5;
  .image-container {
    width: 360px;
    height: 425px;
    display: flex;
    align-items: center;
    justify-content: center;
    .nurse-img {
      width: 350px;
      height: 414px;
      border-radius: 24px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      opacity: 0.5;
      transform: scale(0.9);
    }
    .nurse-animate {
      opacity: 1;
      transform: scale(1);
      transition: all 1s ease-out;
    }
  }

  .details-container {
    width: 100%;
    height: 75px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    // background-color: red;
    // opacity: 1;
    // position: absolute;
    // bottom: 0;
    // border-bottom-left-radius: 24px;
    // border-bottom-right-radius: 24px;
    // background-color: red;
    .details-wrapper {
      height: 82.5%;
      width: 90%;

      .name-container {
        //   background-color: blue;
        height: 50%;
        width: 100%;
        font-size: $large;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $gray-6;
        // border-bottom: 2px solid $gray-5;
        // background-color: yellow;
        font-weight: 900;
        // padding-left: 8px;
        font-family: "Merri";
      }
      .job-container {
        width: 100%;
        height: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: $medium;

        // border-top: 1px solid black;
        // background-color: red;
        p {
          color: $gray-5;
          letter-spacing: 0.5px;
          // margin-left: 8px;
          font-weight: 700;
        }
      }
    }
  }
}
.animate-nurse {
  transform: scale(1);
  transition: transform 1s ease-out;
}

@media screen and (max-width: 480px) {
  .card-container {
    width: 100vw;
    height: 550px;
    padding-bottom: 60px;

    .image-container {
      width: 100%;
      height: 100%;
      // background-color: red;
      // border: 2px solid $gray-5;
      display: flex;
      align-items: center;
      justify-content: center;
      .nurse-img {
        width: 90%;
        height: 450px;
        border-radius: 24px;

        // border: 1px solid $gray-3;
        background-size: 100% 100%;
        background-position: center;
        opacity: 1;
        transform: scale(1);
      }
      .nurse-animate {
        background-size: 105% 105%;
        transition: background-size 0.7s ease-out;
      }
    }
    .details-container {
      .details-wrapper {
        width: 95vw;
      }
    }
  }
}
